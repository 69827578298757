import React from "react";

import { Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";
import JobCard from "../components/job-card";
import { Row } from "reactstrap";

const Jobs = ({ data, location, pageContext }) => {
    const jobs = data.GajCMS.get_all_jobs;

    return (
        <>
            <MetaData location={location} />
            <Layout>
                <div className="w-75 mx-auto mt-3">
                    <h1 className="special-heading ">Jobs</h1>
                    <hr />
                </div>
                <Row className="post-feed w-50 mx-auto">
                    {jobs.map((job) => {
                        return <JobCard {...job} />;
                    })}
                </Row>
            </Layout>
        </>
    );
};

export default Jobs;

export const query = graphql`
    query {
        GajCMS {
            get_all_jobs {
                id
                title
                company
                location
                description
                min_salary
                max_salary
                industries
                type
                job_functions
                contact_email
            }
        }
    }
`;
